import { Spin } from "antd";
import "antd/dist/antd.css";
import React, { Suspense, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { getLang, setLang } from "./utils/localStorage";
import { getLocationLocale } from "./utils/utils";
import ShoppingCartProvider from "./product/ShoppingCartContext";

const currentLang = getLang();
if (!currentLang) {
  getLocationLocale().then((response) => {
    setLang(response);
  });
}

const Homepage = React.lazy(() => import("./home"));
const SolutionPage = React.lazy(() => import("./solution"));
const CourseAdvanced = React.lazy(() =>
  import("./solution/course/index_advanced")
);
const CourseBasic = React.lazy(() => import("./solution/course/index_basic"));
const Laboratory = React.lazy(() => import("./solution/training/index_lab"));
const TrainingTeacher = React.lazy(() =>
  import("./solution/training/index_training")
);
const PrivatePolicy = React.lazy(() => import("./misc/private-policy"));
const Product = React.lazy(() => import("./product"));
const Catalogue = React.lazy(() => import("./product/catalogue/catalogue"));
const Blog = React.lazy(() => import("./blog"));
const Blogs = React.lazy(() => import("./blog_list"));
const Download = React.lazy(() => import("./download"));
const Cart = React.lazy(() => import("./cart"));
const Lecturer = React.lazy(() => import("./lecturer"));

const lang = getLang();

if (lang) {
  ReactDOM.render(
    <ShoppingCartProvider>
      <Router>
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route exact path="/support"></Route>
            <Route exact path="/">
              <Redirect to={`/${lang}`} />
            </Route>
            <Route path="/:lang/solution">
              <SolutionPage />
            </Route>
            <Route path="/:lang/product/:id">
              <Product />
            </Route>
            <Route path="/:lang/course/basic">
              <CourseBasic />
            </Route>
            <Route path="/:lang/course/advanced">
              <CourseAdvanced />
            </Route>
            <Route path="/:lang/training/teacher">
              <TrainingTeacher />
            </Route>
            <Route path="/:lang/training/laboratory">
              <Laboratory />
            </Route>

            <Route
              path="/:lang/blog/:id"
              render={() => <Blog key={Date.now()} />}
            />
            <Route path="/:lang/blog">
              <Blogs />
            </Route>

            <Route path="/:lang/download">
              <Download />
            </Route>
            <Route path="/:lang/private-policy">
              <PrivatePolicy />
            </Route>
            <Route path="/:lang/product">
              <Catalogue />
            </Route>
            <Route path="/:lang/cart">
              <Cart />
            </Route>
            <Route path="/:lang/teacher">
          <Lecturer />
        </Route>
            <Route path="/:lang/">
              <Homepage />
            </Route>


          </Switch>
        </Suspense>
      </Router>
    </ShoppingCartProvider>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <React.StrictMode></React.StrictMode>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
