import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from "react";

import { notification } from "antd";
import setupLang from "../utils/language";
import * as api from "../utils/api";
import { useRouteMatch, Link, useHistory, useParams } from "react-router-dom";

const ShoppingCartContext = createContext({});

export const useShoppingCartContext = () => useContext(ShoppingCartContext);

const ShoppingCartProvider = ({ children }) => {
  const t = setupLang("products/catalogue");
  const [isShowPopup, setShowPopup] = useState(false);
  const handleClose = (e) => {
    e.preventDefault();
    setShowPopup(false);
  };
  const useLocalStorage = (key, initialValue) => {
    const [value, setValue] = useState(() => {
      const jsonValue = localStorage.getItem(key);
      if (jsonValue != null) return JSON.parse(jsonValue);

      if (typeof initialValue === "function") {
        return initialValue();
      } else {
        return initialValue;
      }
    });

    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
  };

  const [cartItems, setCartItems] = useLocalStorage("carts", []);
  const [categories, setCategories] = useState([]);
  const [loadingCategory, setLoadingCategory] = useState(true);

  const getCategories = async () => {
    try {
      const response = await api.LoadListCategory();
      setCategories(response.data.data);
      setLoadingCategory(false);
    } catch (e) {
      setCategories([]);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const cartQuantity = cartItems.reduce(
    (quantity, item) => item.quantity + quantity,
    0
  );

  const openNotification = () => {
    notification.open({
      message: t("add_cart_noti_title"),
      description: t("add_cart_noti_success"),
      placement: "bottomRight",
      onClick: () => {
        console.log("Notification Clicked!");
      },
    });
  };

  const getItemQuantity = (id) => {
    return cartItems.find((item) => item.cart_id === id)?.quantity || 0;
  };

  const myMouseHover = (test) => {
    setTimeout(() => {
      test.removeEventListener("mouseover", myMouseHover);
    }, 3000);
  };

  const showAlertThenScrollView = () => {
    setShowPopup(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const increaseCartQuantity = (id, action = null) => {
    if (action) {
      showAlertThenScrollView();
    }
    setCartItems((currItems) => {
      if (currItems.find((item) => item.cart_id === id) == null) {
        return [...currItems, { cart_id: id, quantity: 1 }];
      } else {
        return currItems.map((item) => {
          if (item.cart_id === id) {
            return { ...item, quantity: item.quantity + 1 };
          } else {
            return item;
          }
        });
      }
    });
  };

  const datHang = () => {
    setCartItems([]);
  };

  const decreaseCartQuantity = (id) => {
    setCartItems((currItems) => {
      if (currItems.find((item) => item.cart_id === id)?.quantity === 1) {
        return currItems.map((item) => {
          if (item.cart_id === id) {
            return {
              ...item,
              quantity: 1,
            };
          } else {
            return item;
          }
        });
      } else {
        return currItems.map((item) => {
          if (item.cart_id === id) {
            return {
              ...item,
              quantity: item.quantity - 1,
            };
          } else {
            return item;
          }
        });
      }
    });
  };
  const removeFromCart = (id) => {
    setCartItems((currItems) => {
      return currItems.filter((item) => item.cart_id !== id);
    });
  };

  return (
    <ShoppingCartContext.Provider
      value={{
        cartItems,
        getItemQuantity,
        increaseCartQuantity,
        decreaseCartQuantity,
        removeFromCart,
        cartQuantity,
        categories,
        loadingCategory,
        datHang,
        isShowPopup,
        handleClose,
        setShowPopup,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
};

export default ShoppingCartProvider;
