import axios from "axios";

export const getFullFilledArray = (len) =>
  len === 0 || !len
    ? []
    : Array(len)
        .fill(0)
        .map((_, idx) => idx);

export const getLocationLocale = async () => {
  try {
    const url = `${process.env.REACT_APP_API_ROOT}/guest/getGeoCountryIso`;
    const res = await axios.get(url);
    if (res.data.countryIso === "VN") {
      return "vi";
    } else return "en";
  } catch (error) {
    console.log(error);
    return "en";
  }
};
