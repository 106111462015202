import { getLang } from "./localStorage";

export const SUPPORT_LANG = ["vi", "en"];

const EMPTY_OBJ = {};

const memoFunction = {};

const setupLang = (filename) => {
  if (memoFunction[filename]) {
    return memoFunction[filename];
  }

  const translation = {};

  SUPPORT_LANG.forEach((lang) => {
    try {
      translation[lang] = require(`../translation/${lang}/${filename}.json`);
    } catch (err) {
      console.log(err);
      translation[lang] = EMPTY_OBJ;
    }
  });

  const currentLang = getLang();
  const t = (key, defaultKey = true) =>
    translation[currentLang][key]
      ? translation[currentLang][key]
      : defaultKey
      ? `<${key}>`
      : undefined;

  memoFunction[filename] = t;

  return t;
};

export default setupLang;
