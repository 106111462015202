import axios from "axios";
import { getLang } from "./localStorage";
const API_ROOT = process.env.REACT_APP_API_ROOT || "https://garastem.com/api";
// const API_ROOT = "http://localhost:5001/api";
const DOMAIN = "https://garastem.com";
export function fetchPost({ page, cat, keyword }) {
  let queryString = "";
  if (page) queryString = queryString + `page=${page}&`;
  if (cat) queryString = queryString + `cat=${cat}&`;
  if (keyword) queryString = queryString + `keyword=${keyword}&`;
  queryString = queryString + `lang=${getLang()}&`;
  return axios.get(
    `${API_ROOT}/post${queryString.length > 0 ? `?${queryString}` : ""}`
  );
}

export function getCat() {
  return axios.get(`${API_ROOT}/post/cat?lang=${getLang()}`);
}

export function fetchPostDetail(id) {
  return axios.get(`${API_ROOT}/post/${id}?lang=${getLang()}`);
}

export function fetchTinhHuyenXa() {
  return axios.get(`${API_ROOT}/public/xaHuyenTinh?lang=${getLang()}`);
}

export function addOrderInfo(order_info) {
  return axios.post(
    `${API_ROOT}/public/addNewOrder?lang=${getLang()}`,
    order_info
  );
}

export function LoadListCategory() {
  return axios.get(`${API_ROOT}/public/category?lang=${getLang()}`);
}

export function LoadCategoryItem(id) {
  return axios.get(`${API_ROOT}/public/category/${id}?lang=${getLang()}`);
}
