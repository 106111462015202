import { SUPPORT_LANG } from "./language";

const LS_POST_PREFIX = "post-";
const LS_LANG = "lang";

export const updatePostToLocalStorage = (posts) => {
  const currentLang = getLang();
  posts.forEach((v) => {
    if (v._id) {
      localStorage.setItem(
        `${LS_POST_PREFIX}${v._id}_${currentLang}`,
        JSON.stringify(v)
      );
    }
  });
};

export const getRandomPostFromLocalStorage = (num) => {
  try {
    const arr = [];
    const currentLang = getLang();
    const postKeys = Object.keys(localStorage).filter(
      (k) => k.indexOf(LS_POST_PREFIX) === 0 && k.indexOf(currentLang) >= 0
    );

    if (postKeys.length === 0) return [];

    const startIndex =
      num >= postKeys.length ? 0 : Math.floor(Math.random() * 1000) % num;

    for (let i = startIndex; i < startIndex + num; i++) {
      const post = JSON.parse(
        localStorage.getItem(postKeys[i % postKeys.length])
      );
      arr.push(post);
    }

    return arr;
  } catch (err) {
    console.log(err);
    localStorage.clear();
    return [];
  }
};

export const setLang = (lang) => {
  const currentLang = getLang();
  const newLang = lang.toLowerCase();
  if (newLang !== currentLang && SUPPORT_LANG.includes(newLang)) {
    localStorage.setItem(LS_LANG, lang.toLowerCase());
    window.location.replace(
      window.location.href.replace(`/${currentLang}`, `/${newLang}`)
    );
  }
};

export const getLang = () => {
  return localStorage.getItem(LS_LANG);
};

export const invalidatePost = (id) => {
  SUPPORT_LANG.forEach((l) => {
    localStorage.removeItem(`${LS_POST_PREFIX}${id}_${l}`);
  });
};
